


















































































































































































































































































import Vue from 'vue';

import { required } from 'vuelidate/lib/validators';
import AccountButton from './components/account_button/index.vue';
import WbContentWrapper from '@/components/content_wrapper/index.vue';
import WbPlaidLink from './components/plaid/plaid_link/index.vue';
import Disclosure from '@/views/student/components/disclosure/index.vue';
import UnlinkAccountDialog from './components/unlink_account_dialog/index.vue';
import { CheckoutPaymentRequestBodyVGS } from '../../dependencies/complete_checkout';
import WbTextField from '@/components/text_field/index.vue';

import getSavedAccount, { SavedAccount } from './dependencies/get_saved_account';
import unlinkSavedAccount from './dependencies/unlink_saved_account';

export default Vue.extend({
  name: 'DirectDebit',

  components: {
    AccountButton,
    WbContentWrapper,
    WbPlaidLink,
    Disclosure,
    UnlinkAccountDialog,
    WbTextField,
  },

  props: {
    projectId: {
      type: String,
    },
    projectData: {
      type: Object,
    },
    loanAgreementUrl: {
      type: String,
    },
    paymentState: {
      type: String,
    },
    origin: {
      type: String,
    },
    numberOfMonths: {
      type: Number,
    },
    ppmOnly: {
      type: Boolean,
    },
    termsOfServiceUrl: {
      type: String,
    },
  },

  data() {
    return {
      hasAgreedToConditions: false,
      accountDialog: false,
      savedAccount: null as SavedAccount | null,
      state: 'loaded',
      linkToken: '',
      plaidEnv: process.env.VUE_APP_PLAID_ENV,
      isConnectingManually: false,
      form: {
        name: '',
        routingNumber: '',
        accountNumber: '',
      },
    };
  },

  validations: {
    form: {
      name: { required },
      routingNumber: { required },
      accountNumber: { required },
    },
  },

  watch: {
    '$route.hash': function (newHash, oldHash) {
      if (oldHash === '#dialog') {
        this.accountDialog = false;
      }
    },
  },

  async mounted() {
    await this.loadSavedAccount();
  },

  methods: {
    async loadSavedAccount() {
      try {
        this.state = 'loading';
        this.savedAccount = await getSavedAccount();
        this.$emit('setErrorMessage', '');
      } catch (error) {
        const err = error as any;
        if (err?.data?.message && err?.data?.type) {
          this.$emit('setErrorMessage', err.data.message);
        } else {
          this.$emit('error', err);
        }
      } finally {
        this.state = 'loaded';
      }
    },

    async unlinkSavedAccount() {
      try {
        this.state = 'loading';
        await unlinkSavedAccount();
        this.savedAccount = null;
        this.accountDialog = false;
      } catch (error) {
        this.$emit('error', error);
      } finally {
        this.state = 'loaded';
      }
    },

    disclosureChange(agreed: boolean) {
      this.hasAgreedToConditions = agreed;
    },

    downloadAgreementPlan(event: Event) {
      event.preventDefault();
      this.$emit('downloadAgreementPlan', this.$props.origin);
    },

    async onAccountLinkedSuccess() {
      await this.loadSavedAccount();
    },

    handleError(error: any) {
      this.$emit('error', error);
    },

    async completePurchase() {
      const sessionId = String(this.$route.params.sessionId);
      const checkoutPaymentRequest: CheckoutPaymentRequestBodyVGS = {
        paymentMethod: 'bank',
        provider: 'vgs',
        paymentData: {
          bank: {
            plaidIntegration: true,
          },
        },
        sessionId,
      };

      this.$emit('complete-checkout', checkoutPaymentRequest);
    },

    async completePurchaseWithManual() {
      const sessionId = String(this.$route.params.sessionId);
      const checkoutPaymentRequest: CheckoutPaymentRequestBodyVGS = {
        paymentMethod: 'bank',
        provider: 'vgs',
        sessionId,
        paymentData: {
          bank: {
            accountNumber: this.form.accountNumber,
            routingNumber: this.form.routingNumber,
            plaidIntegration: false,
          },
        },
      };
      this.$emit('complete-checkout', checkoutPaymentRequest);
    },

    onEditAccount() {
      this.accountDialog = true;
      this.$router.push('#dialog');
    },

    closeDialog() {
      this.$router.back();
    },
  },
});
