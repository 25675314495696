



































































import Vue from 'vue';
import { VueMaskDirective } from 'v-mask';
import { required } from 'vuelidate/lib/validators';
import WbTextField from '@/components/text_field/index.vue';
import sendOtp from '../dependencies/send_otp';
import WbCountry from '@/components/country_dropdown/index.vue';
import initCheckout, { Country } from '../dependencies/init_checkout';
import { sortCountries, countryPhoneFormat } from '@/components/country_dropdown/helpers';

Vue.directive('mask', VueMaskDirective);

export default Vue.extend({

  name: 'StudentAuthLoginPhone',

  components: {
    WbTextField,
    WbCountry,
  },
  data() {
    return {
      error: '',
      state: '',
      countries: [] as Country[],
      form: {
        phoneNumber: '',
        country: {} as Country,
      },
    };
  },

  validations: {
    form: {
      phoneNumber: {
        required,
      },
    },
  },

  computed: {
    phoneNumberErrors() {
      const errors: string[] = [];
      if (!this.$v?.form?.email?.$dirty) return errors;
      if (!this.$v?.form?.phoneNumber?.required) errors.push('Phone Number is required');

      return errors;
    },

    countryPhoneFormat() {
      if (!this.form.country.id) return '';
      // @ts-ignore
      return countryPhoneFormat[this.form.country.id];
    },
  },

  async mounted() {
    const state = await initCheckout();
    this.countries = sortCountries(state.countries);
    [this.form.country] = this.countries;
  },

  methods: {
    isNumber(evt: KeyboardEvent): void {
      const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];
      const keyPressed: string = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
    async submit() {
      this.error = '';
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      try {
        const { phoneNumber, country } = this.form;

        const rawPhoneNumber = phoneNumber.replace(/\D/g, '');

        this.state = 'loading';
        const sessionId = await sendOtp(rawPhoneNumber, country.callingCode, country.id);
        this.$router.replace({
          path: '/student/verify-otp',
          query: {
            ...this.$route.query,
            sessionId,
          },
        });
        this.state = 'loaded';
      } catch (error) {
        this.state = 'error';
        this.error = (error as any)?.data?.message || "We've experienced an error";
      }
    },
  },
});
