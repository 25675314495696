







































































































































import Vue from 'vue';

export default Vue.extend({
  props: {
    projectData: {
      type: Object,
    },
  },

  data() {
    return {
      projectId: '',
    };
  },

  mounted() {
    this.projectId = String(this.$route.params.projectId);
  },
});
