



































































































































































































import Vue from 'vue';

import Dinero from 'dinero.js';
import Disclosure from '@/views/student/components/disclosure/index.vue';
import { CheckoutPaymentRequestBodyBs } from '../../dependencies/complete_checkout_bs';
import getPrepaymentInfo, { PrepaymentInfo } from './dependencies/get_prepayment_info';

import getCardSessionToken from './dependencies/get_card_token';
import WbTextFieldLoader from '@/components/skeleton_loaders/text_field.vue';
import WbParagraphLoader from '@/components/skeleton_loaders/paragraph.vue';
import CardBrand from './components/index.vue';

interface CustomerInfo extends PrepaymentInfo {
  amount: number;
}

export default Vue.extend({
  name: 'CreditCard',

  components: {
    Disclosure,
    WbTextFieldLoader,
    WbParagraphLoader,
    CardBrand,
  },

  props: {
    businessName: {
      type: String,
    },
    projectData: {
      type: Object,
    },
    paymentState: {
      type: String,
    },
    origin: {
      type: String,
    },
    numberOfMonths: {
      type: Number,
    },
    ppmOnly: {
      type: Boolean,
    },
    dueToday: {
      type: Number,
    },
    termsOfServiceUrl: {
      type: String,
    },
  },

  data() {
    return {
      error: '',
      invalid: true,
      creditCardVendor: '',
      hasAgreedToConditions: false,
      env: process.env.VUE_APP_BLUESNAP_ENV === 'production'
        ? 'https://ws.bluesnap.com'
        : 'https://sandbox.bluesnap.com',
      form: {
        nameOnCard: '',
        cardToken: '',
      },
      errors: {
        nameOnCard: '',
        cardNumber: '',
        expiryDate: '',
        cvv: '',
      },
      formInputsState: 'loading',
      prepaymentInfo: {} as Partial<CustomerInfo>,
    };
  },

  computed: {
    isFormValid(this: any) {
      return this.hasAgreedToConditions && this.form.nameOnCard;
    },
  },

  async mounted() {
    const { projectId, sessionId } = this.$route.params;

    try {
      this.formInputsState = 'loading';
      this.form.cardToken = await getCardSessionToken({ projectId, sessionId });

      this.prepaymentInfo = {
        ...(await getPrepaymentInfo({ projectId, sessionId })),
        amount: Dinero({ amount: this.dueToday }).toUnit(),
      };

      const obj = this.buildBluesnap(this.form.cardToken);
      window.bluesnap.hostedPaymentFieldsCreate(obj);

      // On bluesnap build success, state is set to loaded.
      // if more than 10s goes by, we assume it failed.
      setTimeout(() => {
        if (this.formInputsState === 'loading') {
          this.$emit('setErrorMessage', `
            We had trouble loading the credit card form. Try and refresh your browser.
            If this persists, please reach out to us.
          `);
        }
      }, 10000);
    } catch (error) {
      console.error('error building bluesnap ', error);
      this.$emit('setErrorMessage', `
        We had trouble loading the credit card form. Try and refresh your browser.
        If this persists, please reach out to us.
      `);
    }
  },

  methods: {
    downloadAgreementPlan(event: Event) {
      event.preventDefault();
      this.$emit('downloadAgreementPlan', this.$props.origin);
    },

    disclosureChange(agreed: boolean) {
      this.hasAgreedToConditions = agreed;
    },

    onChangeName() {
      if (!this.form.nameOnCard) {
        this.errors.nameOnCard = 'Field Required';
      } else {
        this.errors.nameOnCard = '';
      }
    },

    async completePurchase() {
      this.error = '';

      if (!this.form.nameOnCard) {
        this.errors.nameOnCard = 'Field Required';
        return;
      }

      window.bluesnap.hostedPaymentFieldsSubmitData(
        async (callback) => {
          if (callback.error != null) {
            this.error = `Authentication Failed: We apologize, but we were unable to verify your payment
            authentication. Please ensure that the information provided is correct and try again. If the
            issue persists, please contact our customer support for further assistance.`;
            return;
          }

          const { cardData } = callback;
          await this.sendPayment(cardData);
        }, this.prepaymentInfo,
      );
    },
    async sendPayment(cardData: any) {
      const sessionId = String(this.$route.params.sessionId);
      const form: CheckoutPaymentRequestBodyBs = {
        sessionId,
        paymentMethod: 'card',
        provider: 'bluesnap',
        paymentData: {
          card: {
            cardToken: this.form.cardToken,
            lastFourDigits: cardData.last4Digits,
            cardExp: cardData.exp,
            cardHolderName: this.form.nameOnCard,
            postalCode: this.prepaymentInfo.billingZip ?? null,
            countryCode: this.prepaymentInfo.billingCountry ?? null,
          },
        },
      };

      this.$emit('complete-checkout', form);
    },

    setCard(variant: string) {
      this.creditCardVendor = variant;
    },

    buildBluesnap(sessiontoken: string) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const componentRef = this;
      return {
        // insert your Hosted Payment Fields token
        token: sessiontoken,
        '3DS': true,
        onFieldEventHandler: {
          setupComplete: () => {
            this.formInputsState = 'loaded';
          },

          onFocus(tagId) {
            // Handle focus
            // changeImpactedElement(tagId, 'hosted-field-valid hosted-field-invalid', 'hosted-field-focus');
          },

          onBlur(tagId) {
            // Handle blur
            // changeImpactedElement(tagId, 'hosted-field-focus');
          },

          onError: (tagId: string, errorCode: string, errorDescription: string) => {
            // Temporary handler to hide 3D Secure error from not being enabled
            if (errorDescription.includes('3D Secure')) {
              return;
            }
            switch (tagId) {
            case 'ccn':
              this.errors.cardNumber = errorDescription;
              break;
            case 'exp':
              this.errors.expiryDate = errorDescription;
              break;
            case 'cvv':
              this.errors.cvv = errorDescription;
              break;
            default:
              break;
            }
          },

          onType(tagId, cardType, cardData) {
            componentRef.creditCardVendor = cardType.toLowerCase();
          },

          onValid: (tagId) => {
            switch (tagId) {
            case 'ccn':
              this.errors.cardNumber = '';
              break;
            case 'exp':
              this.errors.expiryDate = '';
              break;
            case 'cvv':
              this.errors.cvv = '';
              break;
            default:
              break;
            }
          },
        },
        // styling is optional
        style: {
          ':focus': {
            color: '#40496b',
          },
          input: {
            color: '#40496b',
            'font-size': '0.875rem',
          },
        },
        ccnPlaceHolder: '4111222233334444',
        cvvPlaceHolder: '123',
        expPlaceHolder: 'MM / YY',
      };
    },
  },
});
