







































































































import Vue from 'vue';

export default Vue.extend({
  name: 'PaymentOptionButton',
  props: {
    totalPrice: {
      type: Number,
    },
    firstPaymentInCents: {
      type: Number,
    },
    valuePerMonth: {
      type: Number,
    },
    installments: {
      type: Number,
    },
    selected: {
      type: Boolean,
    },
    currencyCode: {
      type: String,
    },
    hasDownPayment: {
      type: Boolean,
    },
    taxAmountInCents: {
      type: Number,
    },
    origin: {
      type: String,
      required: true,
    },
  },

  computed: {
    paymentsText() {
      return (`${this.installments} ${this.installments === 1 ? 'Payment' : 'Payments'}`);
    },
  },
});
