

































































































































































































import Vue from 'vue';

import Dinero from 'dinero.js';
import Disclosure from '@/views/student/components/disclosure/index.vue';
import { CheckoutPaymentRequestBodyBs } from '../../dependencies/complete_checkout_bs';
import getPrepaymentInfo, { PrepaymentInfo } from './dependencies/get_prepayment_info';

import getCardSessionToken from './dependencies/get_card_token';
import WbTextFieldLoader from '@/components/skeleton_loaders/text_field.vue';
import WbParagraphLoader from '@/components/skeleton_loaders/paragraph.vue';
import CardBrand from './components/index.vue';
import { TokenResponse } from './dependencies/types';

interface CustomerInfo extends PrepaymentInfo {
  amount: number;
}

export default Vue.extend({
  name: 'CreditCard',

  components: {
    Disclosure,
    WbTextFieldLoader,
    WbParagraphLoader,
    CardBrand,
  },

  props: {
    businessName: {
      type: String,
    },
    projectData: {
      type: Object,
    },
    paymentState: {
      type: String,
    },
    origin: {
      type: String,
    },
    numberOfMonths: {
      type: Number,
    },
    ppmOnly: {
      type: Boolean,
    },
    dueToday: {
      type: Number,
    },
    termsOfServiceUrl: {
      type: String,
    },
  },

  data() {
    return {
      error: '',
      invalid: true,
      creditCardVendor: '',
      hasAgreedToConditions: false,
      form: {
        nameOnCard: '',
        cardToken: '',
      },
      errors: {
        nameOnCard: '',
        ccnumber: '',
        ccexp: '',
        cvv: '',
      },
      formInputsState: 'loading',
      scriptLoaded: false,
      prepaymentInfo: {} as Partial<CustomerInfo>,
    };
  },

  computed: {
    isFormValid(this: any) {
      return this.hasAgreedToConditions && this.form.nameOnCard;
    },
  },

  async mounted() {
    this.formInputsState = 'loaded';
    const { projectId, sessionId } = this.$route.params;
    this.prepaymentInfo = {
      ...(await getPrepaymentInfo({ projectId, sessionId })),
      amount: Dinero({ amount: this.dueToday }).toUnit(),
    };
    const cardToken = await getCardSessionToken({ projectId, sessionId });
    const { tokenizationKey, collectCheckoutKey } = this.getScriptCredentials(cardToken);

    await this.setScript({
      src: 'https://secure.networkmerchants.com/token/Collect.js',
      'data-tokenization-key': tokenizationKey,
    });

    await this.setScript({
      src: 'https://secure.networkmerchants.com/js/v1/Gateway.js',
    });

    // @ts-ignore
    const gateway = window.Gateway.create(collectCheckoutKey);
    const threeDS = gateway.get3DSecure();
    const ignore3DS = !this.is3DSecureEnabled(await gateway.configPromise);

    // TODO: @lucas fix this TS-IGNORE
    // @ts-ignore
    await window.CollectJS.configure({
      variant: 'inline',
      styleSniffer: true,
      validationCallback: (field, status, message) => {
        if (!status) {
          this.errors[field] = message;
        } else {
          this.errors[field] = '';
        }
      },
      timeoutDuration: 10000,
      timeoutCallback: () => {
        console.log('timeout');
        this.$emit('setErrorMessage', `
          We had trouble loading the credit card form. Try and refresh your browser.
          If this persists, please reach out to us.
        `);
      },
      fieldsAvailableCallback: () => {
        console.log('fields available');
      },
      placeholderCss: {
        color: 'rgb(86 86 86 / 50%) !important',
      },
      fields: {
        ccnumber: {
          placeholder: '4111 2222 3333 4444',
          selector: '#ccnumber',
          // enableCardBrandPreviews: true,
        },
        ccexp: {
          placeholder: 'MM / YY',
          selector: '#ccexp',
        },
        cvv: {
          placeholder: '123',
          selector: '#cvv',
        },
      },
      callback: async (token: TokenResponse) => {
        try {
          if (this.paymentState === 'loading') {
            return;
          }
          let options = {
            paymentToken: token.token,
            currency: this.prepaymentInfo.currency,
            amount: this.prepaymentInfo.amount,
            email: this.prepaymentInfo.email,
            country: this.prepaymentInfo.billingCountry,
            firstName: this.prepaymentInfo.billingFirstName,
            lastName: this.prepaymentInfo.billingLastName,
            postalCode: this.prepaymentInfo.billingZip,
          };
          const threeDSPromise = new Promise((resolve, reject) => {
            if (ignore3DS) {
              resolve('');
            }
            const threeDSecureInterface = threeDS.createUI(options);
            threeDSecureInterface.start('#threeDSMountPoint');

            threeDSecureInterface.on('challenge', (e) => {
              console.log('Challenged');
            });

            threeDSecureInterface.on('complete', (e) => {
              options = { ...options, ...e };
              resolve('');
            });

            threeDSecureInterface.on('failure', (e) => {
              reject(e);
            });

            gateway.on('error', (e) => {
              console.error(e);
              reject(e);
            });
          });
          await threeDSPromise;
          await this.sendPayment({ ...token, ...options });
        } catch (error) {
          console.log(error);
          this.$emit('setErrorMessage', `Authentication Failed: We apologize, but we were unable to verify your payment
            authentication. Please ensure that the information provided is correct and try again. If the
            issue persists, please contact our customer support for further assistance.`);
        }
      },
    });
    this.scriptLoaded = false;
    const inputFound = await this.findCCNumberInput();
    if (inputFound) {
      this.scriptLoaded = true;
    } else {
      this.$emit('setErrorMessage', `
        We had trouble loading the credit card form. Try and refresh your browser.
        If this persists, please reach out to us.
      `);
    }
  },

  methods: {
    findCCNumberInput() {
      return new Promise((resolve) => {
        const intervalId = setInterval(() => {
          const iframe = document.getElementById('CollectJSInlineccnumber');
          if (iframe && iframe.ownerDocument) {
            const ccNumberInput = iframe.ownerDocument.getElementById('ccnumber');
            if (ccNumberInput) {
              clearInterval(intervalId);
              resolve(true);
            }
          }
        }, 1000);

        setTimeout(() => {
          clearInterval(intervalId);
          resolve(false);
        }, 5000);
      });
    },
    getScriptCredentials(credentials: string | Record<string, string>) {
      if (typeof credentials !== 'string') {
        return {
          tokenizationKey: credentials.tokenizationKey,
          collectCheckoutKey: credentials.collectCheckoutKey,
        };
      }
      const parsed = JSON.parse(credentials);
      return {
        tokenizationKey: parsed.tokenizationKey,
        collectCheckoutKey: parsed.collectCheckoutKey,
      };
    },
    is3DSecureEnabled(gateway: any) {
      return gateway.find((config) => config.name === '3DSecure')?.status === 'active';
    },
    async setScript(attr: Record<string, string>): Promise<void> {
      const script = document.createElement('script');
      Object.entries(attr).forEach(([key, value]) => {
        script.setAttribute(key, value);
      });

      const scriptLoadPromise = new Promise((resolve) => {
        script.onload = resolve;
      });

      document.head.appendChild(script);

      await scriptLoadPromise;
    },
    downloadAgreementPlan(event: Event) {
      event.preventDefault();
      this.$emit('downloadAgreementPlan', this.$props.origin);
    },

    disclosureChange(agreed: boolean) {
      this.hasAgreedToConditions = agreed;
    },

    onChangeName() {
      if (!this.form.nameOnCard) {
        this.errors.nameOnCard = 'Field Required';
      } else {
        this.errors.nameOnCard = '';
      }
    },
    async sendPayment(cardData: any) {
      if (!this.form.nameOnCard) {
        this.errors.nameOnCard = 'Field Required';
        return;
      }

      const sessionId = String(this.$route.params.sessionId);
      const form: CheckoutPaymentRequestBodyBs = {
        sessionId,
        paymentMethod: 'card',
        provider: 'nmi',
        paymentData: {
          card: {
            cardToken: cardData.token,
            lastFourDigits: cardData.card.number.slice(-4),
            cardExp: `${cardData.card.exp.slice(0, 2)}/${cardData.card.exp.slice(2)}`,
            cardHolderName: this.form.nameOnCard,
            countryCode: this.prepaymentInfo.billingCountry ?? null,
            postalCode: this.prepaymentInfo.billingZip ?? null,
          },
        },
      };

      this.$emit('complete-checkout', form);
    },
  },
});
