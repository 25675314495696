import { Country } from '@/views/student/auth/views/dependencies/init_checkout';

export const countryCodeToFlag = (countryCode: string) => {
  const offset = 127397;
  if (!countryCode) return '';
  return [...countryCode.toUpperCase()]
    .map((char) => String.fromCodePoint(char.charCodeAt(0) + offset))
    .join('');
};

export const sortCountries = (countries: Country[]) => {
  const cache = [...countries];
  const order = ['US', 'CA', 'GB', 'DE', 'FR'];
  const reorderedCountryCodes = cache.sort((a, b) => {
    const indexA = order.indexOf(a.id);
    const indexB = order.indexOf(b.id);

    if (indexA === -1 && indexB === -1) {
      return a.id.localeCompare(b.id);
    }
    if (indexA === -1) {
      return 1;
    }
    if (indexB === -1) {
      return -1;
    }

    return indexA - indexB;
  });
  return reorderedCountryCodes;
};

export const countryPhoneFormat = {
  US: '(###) ###-####',
  CA: '(###) ###-####',
  GB: '##### ######',
  DE: '#### #######',
  FR: '## ## ## ## ##',
  RU: '# ### ###-##-##',
  IN: '#####-#####',
  CN: '####-#######',
  JP: '###-####-####',
  IS: '###-####',
  BR: '(##) #####-####',
  AU: '#### ### ###',
  NZ: '##-####-####',
  ZA: '## ### ####',
  SG: '#### ####',
  HK: '#### ####',
  TW: '#### ####',
  KR: '### #######',
  MX: '### ### ####',
  AR: '# ####-####',
  CL: '# ####-####',
  CO: '# ### ####',
  PE: '# ### ####',
  VE: '# ### ####',
  EC: '# ### ####',
  UY: '# ### ####',
  PY: '# ### ####',
  BO: '# ### ####',
  CR: '# #### ####',
  PA: '# ###-####',
  GT: '# ####-####',
  DO: '# ###-####',
  SV: '# ####-####',
  SW: '# ####-####',
};
