











import Vue from 'vue';

export default Vue.extend({
  methods: {
    onCheckboxChange(value: boolean) {
      this.$emit('change', value);
    },
  },
});
