












































































import Vue, { PropType } from 'vue';
import WbTextField from '../text_field/index.vue';
import { countryCodeToFlag, sortCountries } from './helpers';

interface Country {
  id: string;
  callingCode: number;
  name: string;
  requiresPostalCode: boolean;
}

export default Vue.extend({
  components: {
    WbTextField,
  },

  props: {
    value: {
      type: Object as PropType<Country>,
      default: {},
    },

    countries: {
      type: Array as PropType<Country[]>,
      default: () => [],
    },

    showCallCode: {
      default: false,
      type: Boolean,
    },

    disabled: {
      default: false,
      type: Boolean,
    },

    showIcon: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      menuOpen: false,
      search: '',
      referenceWidth: 0,
      loading: true,
    };
  },

  computed: {
    filteredCountries() {
      if (!this.search) {
        return sortCountries(this.countries);
      }
      // @ts-ignore
      const searchTerm = this.search.toLowerCase();
      const updatedList = this.countries.filter((country) => {
        const countryName = country.name.toLowerCase();
        return countryName.startsWith(searchTerm);
      });

      return sortCountries(updatedList);
    },
    resolvedValue() {
      return this.value;
    },
  },

  methods: {
    onChange(newVal) {
      this.menuOpen = false;
      this.$emit('input', newVal);
      this.search = '';
    },

    flagLookup(code: string) {
      return countryCodeToFlag(code);
    },

    countryCodeLookup(code: string) {
      const countryLookup = this.countries.find(
        (country) => country.id === code.toUpperCase(),
      );
      this.$emit('input', countryLookup);

      return countryLookup;
    },

    callingCode(code: number) {
      const countryLookup = this.countries.find(
        (country) => country.callingCode === code,
      );
      this.$emit('input', countryLookup);
    },
  },
});
