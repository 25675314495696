



















































































































import Vue from 'vue';
import {
  isValid, differenceInYears, getDaysInMonth, format,
} from 'date-fns';
import { required, email, numeric } from 'vuelidate/lib/validators';

import WbTextField from '@/components/text_field/index.vue';

import getStudentInfo from './dependencies/get_student_info';
import setStudentInfo from './dependencies/set_student_info';
import dates from './dependencies/dates';

const lessThanTenMillion = (value) => value <= 1000000000;

export default Vue.extend({

  components: {
    WbTextField,
  },
  props: {
    qualificationState: {
      type: String,
    },

    applicationState: {
      type: Object,
    },

    demo: {
      type: Boolean,
    },

    ppmOnly: {
      type: Boolean,
    },
  },

  data() {
    return {
      state: '',
      error: '',
      sessionId: '',
      dbIncome: null,
      form: {
        firstName: '',
        lastName: '',
        email: '',
        selfDeclaredIncome: null,
        birthDate: {
          day: '',
          month: '',
          year: '',
        },
      },
      isDayDisabled: true,
      isMonthDisabled: true,
      months: dates.months,
      days: dates.days,
      years: dates.years,
    };
  },

  computed: {
    validateEmail(this: any) {
      if (!this.$v?.form?.email?.$dirty) {
        return [];
      }
      if (!this.$v?.form?.email?.required) {
        return ['Field Required'];
      }

      if (!this.$v?.form?.email?.email) {
        return ['Invalid Email'];
      }

      return [];
    },

    validateIncome(this: any) {
      if (!this.$v?.form?.selfDeclaredIncome?.$dirty) {
        return [];
      }
      if (!this.$v?.form?.selfDeclaredIncome?.required) {
        return ['Field Required'];
      }

      if (!lessThanTenMillion(this.$v?.form?.selfDeclaredIncome?.$model)) {
        return ['Income maximum exceeded'];
      }
      return [];
    },

    disableInputs(): boolean {
      return this.applicationState.creditCheckStatus === 'completed'
        && this.applicationState.kycStatus === 'completed';
    },
    disableIncome(): boolean {
      return this.applicationState.creditCheckStatus === 'completed'
        && this.applicationState.kycStatus === 'completed' && this.dbIncome !== null;
    },
  },

  validations() {
    const requiredApplicationFields = !this.ppmOnly ? {
      selfDeclaredIncome: {
        required,
        numeric,
        lessThanTenMillion,
      },
      birthDate: {
        day: { required },
        month: { required },
        year: { required },
      },
    } : {};
    return ({
      form: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        ...requiredApplicationFields as any,
      },
    });
  },

  async mounted() {
    await this.validateParams();
  },

  methods: {
    isNumber(evt: KeyboardEvent): void {
      const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];
      const keyPressed: string = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
    async submit() {
      if (!this.demo && this.qualificationState === 'qualified') {
        this.$router.push({ path: 'qualify', query: this.$route.query });
        return;
      }

      this.error = '';
      this.$v.form.$touch();

      if (this.$v.form.$error) {
        return;
      }

      const { projectId, sessionId } = this.$route.params;
      const {
        firstName, lastName, email, selfDeclaredIncome,
      } = this.form;

      const { year, month, day } = this.form.birthDate;
      const birthDate = new Date(Number(year), Number(month) - 1, Number(day));

      const birthDateErrors = [
        this.validateBirthDate('day'),
        this.validateBirthDate('month'),
        this.validateBirthDate('year'),
      ].flat();

      if (!isValid(birthDate) || birthDateErrors.length > 0) {
        this.error = 'Please confirm that you have entered your date of birth correctly. '
        + 'Note: our service is only available to those that are the age of majority in their state or province.';
        return;
      }

      if (differenceInYears(new Date(), birthDate) < 18) {
        this.error = 'Sorry, you must be 18 years or older to use Elective';
        return;
      }

      try {
        this.state = 'loading';

        await setStudentInfo(
          projectId,
          sessionId,
          {
            firstName,
            lastName,
            email,
            birthdate: format(birthDate, 'yyyy-MM-dd'),
            selfDeclaredIncome,
          },
        );

        this.$router.push({ path: 'qualify', query: this.$route.query });
      } catch (error) {
        if (error && (error as any).data) {
          if ((error as any).data.type === 'EmailAlreadyExists') {
            this.error = (error as any).data.message;
          }
        } else {
          this.$emit('error', error);
        }
      } finally {
        this.state = 'loaded';
      }
    },

    validateField(input: string) {
      if (!this.$v?.form?.[input]?.$dirty) {
        return [];
      }
      if (!this.$v?.form?.[input]?.required) {
        return ['Field Required'];
      }

      if (input === 'email' && !this.$v?.form?.email?.email) {
        return ['Invalid Email'];
      }

      return [];
    },

    validateBirthDate(field: string) {
      const birthDate = this.$v?.form?.birthDate;
      const listName = `${field}s`;
      let input = this.form.birthDate[field];

      if (input.length === 1) {
        input = `0${input}`;
      }

      if (!birthDate?.[field].$dirty) {
        return [];
      }

      if (!birthDate?.[field].required) {
        return ['Field Required'];
      }

      if (this[listName].findIndex(
        (a: string) => a === input,
      ) === -1) {
        return [`Enter a valid ${field}`];
      }

      return [];
    },

    displayDays() {
      const { month, year } = this.form.birthDate;
      if (!year) {
        return;
      }
      const totalDays = getDaysInMonth(new Date(Number(year), Number(month) - 1));

      this.days = Array.from({ length: totalDays }, (_, v) => (v >= 9 ? String(v + 1) : `0${v + 1}`));

      this.isDayDisabled = false;
    },

    displayMonths() {
      this.isMonthDisabled = false;
      if (!this.isDayDisabled && this.form.birthDate.month) {
        this.displayDays();
      }
    },

    async validateParams() {
      const { projectId, sessionId } = this.$route.params;

      try {
        this.state = 'loading';
        const queryEmail = this.$route.query.email as string;
        if (queryEmail) {
          this.form.email = queryEmail;
        }

        const studentInfo = await getStudentInfo(projectId, sessionId);
        if (!studentInfo) return;

        this.form.email = studentInfo.email ?? queryEmail ?? '';
        if (this.demo) return;

        this.form.firstName = studentInfo.firstName ?? '';
        this.form.lastName = studentInfo.lastName ?? '';
        this.form.selfDeclaredIncome = studentInfo.selfDeclaredIncome ?? null;
        this.dbIncome = studentInfo.selfDeclaredIncome ?? null;
        this.form.birthDate = studentInfo.birthdate
          ? {
            day: studentInfo.birthdate.split('-')[2],
            month: studentInfo.birthdate.split('-')[1],
            year: studentInfo.birthdate.split('-')[0],
          }
          : this.form.birthDate;
      } catch (error) {
        this.$emit('error', error);
      } finally {
        this.state = 'loaded';
      }
    },
  },
});
